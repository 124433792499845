/* eslint-disable */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax, ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import '../../scss/unite-capsules.scss';
// components
import SEO from '../../components/SEO';
import SectionTrigger from '../../components/SectionTrigger';
import { JumpmanIcon, PlayIcon2 } from '../../components/svgs';
import VideoPlayer from '../../components/VideoPlayer';
// lib
import handlePageTransition from '../../lib/handle-page-transition';

function UniteCapsulesPage({ transitionStatus }) {

  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const data = useStaticQuery(graphql`
    query UniteCapsulesPage {
      allCloudinaryMedia(
        filter: { tags: { eq: "unite-capsules-case-study" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
      allVimeo(
        filter: { name: { regex: "/UNITE_CAPSULES/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            srcset {
              link
            }
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const vimeoMedia = data.allVimeo.edges;
  const images = [];
  const videos = [];
  cloudMedia.forEach(item => {
    const { resource_type: resourceType } = item.node;
    if (resourceType === 'image') {
      images.push(item.node.secure_url);
    }
  });
  vimeoMedia.forEach(item => {
    const { srcset } = item.node;
    videos.push(srcset);
  });
  const [
    img01,
    imgPoster02,
    imgPoster03,
    imgPoster04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
    img13,
  ] = images;
  // const [vid02, vid03, vid04] = videos;
  const vid02 =
    'https://player.vimeo.com/external/433467957.sd.mp4?s=3d169c5283b9a21c6f1f0c2735bd19e3feff43c5&profile_id=165&oauth2_token_id=1365998561';
  const vid03 =
    'https://player.vimeo.com/external/433468020.hd.mp4?s=7f89d0b3a652c37ab29bf8f28801d66d3a48efe0&profile_id=175&oauth2_token_id=1365998561';
  const vid04 =
    'https://player.vimeo.com/external/433468048.hd.mp4?s=ab888e119d6f529cedda9f3a302b642d8226dc35&profile_id=175&oauth2_token_id=1365998561';

  return (
    <div>
      <SEO title="Unite Capsules | Falkon Content" />
      <div className="uc">
        <SectionTrigger bgColor="#d2e6e4" textColor="#fff">
          <Hero images={[img01]} />
        </SectionTrigger>
        <SectionTrigger
          bgColor="#d2e6e4"
          className="uc__section uc__section--1"
          textColor="#000"
        />
        <SectionTrigger
          bgColor="#deb0a4"
          className="uc__section uc__section--2"
          textColor="#fff"
        />
        <SectionTrigger
          bgColor="#d2e6e4"
          className="uc__section uc__section--3"
          textColor="#000"
        />
        <div className="uc__container">
          <Solution images={[imgPoster02]} videos={[vid02]} />
          <Execution
            images={[imgPoster03, imgPoster04]}
            videos={[vid03, vid04]}
          />
          <SupportingContent images={[img05, img06, img07, img08, img09 ]} />
          <Impact images={[ img10, img11, img12, img13 ]} />
        </div>
      </div>
    </div>
  );
}

function Hero({ images }) {
  const [img01] = images;
  return (
    <div className="uc-hero">
      <div className="uc-hero__container">
        <div className="uc-hero__header">
          <JumpmanIcon modifier="uc-hero__header-logo" />
          <h3 className="uc-hero__header-hdg">
            UNITE CAPSULES
          </h3>
          <p className="uc-hero__header-copy">
            JORDAN BRAND
          </p>
        </div>
        <div className="uc-hero__body">
          <h3 className="uc-hero__body-hdg">
            Challenge
          </h3>
          <p className="uc-hero__body-copy">
            How do you continue to keep a rabid fan
            base actively engaged beyond the
            confines of advertising?
          </p>
        </div>
        <p className="uc-hero__img-caption">
          PHOTO: Cyril Masson
        </p>
      </div>
      <img
        className="uc-hero__img"
        src={img01}
        alt="UNITE CAPSULES"
      />
    </div>
  )
}

function Solution({ images, videos }) {
  const [imgPoster02] = images;
  const [vid02] = videos;
  return (
    <div className="uc-solution">
      <div className="uc-solution__title">
        <h3 className="uc-solution__title-hdg">
          Challenge
        </h3>
        <p className="uc-solution__title-copy">
          How do you continue to keep a rabid fan
          base actively engaged beyond the
          confines of advertising?
        </p>
      </div>
      <p className="uc-solution__img-caption">
        PHOTO: Cyril Masson
      </p>
      <p className="uc-solution__tagline">
        <span>HERO FILM FEATURING VIRGIL ABLOH</span>
        Released during the NBA’s All Star Weekend supporting the launch of the JORDAN x OFF WHITE AJ5
      </p>
      <Parallax className="uc-solution__heading" y={[40, -80]}>
        <h3 className="solution">
          Solution
        </h3>
      </Parallax>
      <p className="uc-solution__copy">
        A series of content narratives featuring
        authentic storytelling, focused some of
        the most powerful voices of modern
        sport & culture.
      </p>
      <VideoPlayer
        className="uc-solution__video"
        src={vid02}
        poster={imgPoster02}
      />
    </div>
  );
}

function Execution({ images, videos }) {
  const [imgPoster03, imgPoster04] = images;
  const [vid03, vid04] = videos;
  return (
    <div className="uc-execution">
      <Parallax className="uc-execution__heading" y={[40, -80]}>
        <h3 className="execution">
          Execution
        </h3>
      </Parallax>
      <p className="uc-execution__copy">
        We took inspiration from each of our subjects
        to create a narrative that was most true to
        them, their role in basketball, own personal
        style and unique voice. We then worked in
        close collaboration with each subject to
        further craft the details of the story and
        in some cases even the creative approach and
        filmmaking techniques to further ensure each
        film truly expressed the voice of the subject
        within Jordan’s larger brand voice and spoke
        most authentically to the audience.
      </p>
      <Parallax className="uc-execution__blockquote" y={[20, -80]}>
        <blockquote className="blockquote">
          &ldquo;the most powerful
          voices of modern sport
          & culture&rdquo;
        </blockquote>
      </Parallax>
      <p className="uc-execution__tagline">
        <span>HERO FILM FEATURING ZION WILLIAMSON</span>
        Created to support the launch of the AJ34 just ahead of the 2019-2020
        NBA season and Zion’s highly anticipated rookie season debut.
      </p>
      {/* [418] neymar - scrub from site */}
      <VideoPlayer
        className="uc-execution__video uc-execution__video--1 hide"
        src={vid03}
        poster={imgPoster03}
      />
      <VideoPlayer
        className="uc-execution__video uc-execution__video--2"
        src={vid04}
        poster={imgPoster04}
      />
    </div>
  );
}

function SupportingContent({ images }) {
  const [
    img05,
    img06,
    img07,
    img08,
    img09
  ] = images;
  return (
    <div className="uc-support">
      <Parallax className="uc-support__heading" y={[20, -40]}>
        <h3 className="supporting-content">
          SUPPORTING CONTENT
        </h3>
      </Parallax>
      <p className="uc-support__copy">
        In addition to the hero films, we created
        supporting content such as
        photography assets, GIFs, and
        additional films.
      </p>
      <div className="global-box-shadow uc-support__img uc-support__img--1">
        <img src={img05} alt="" />
        <PlayIcon2 />
      </div>
      <p className="uc-support__img-tagline uc-support__img-tagline--1">
        Zion Williamson Dunk Film.
      </p>

      <div className="global-box-shadow uc-support__img uc-support__img--2">
        <img src={img06} alt="" />
      </div>
      <p className="uc-support__img-tagline uc-support__img-tagline--2">
        PHOTO: Cyril Masson
      </p>
      {/* [418] neymar - scrub from site */}
      <div className="global-box-shadow uc-support__img uc-support__img--3 hide">
        <img src={img07} alt="" />
      </div>
      <div className="global-box-shadow uc-support__img uc-support__img--4">
        <img src={img08} alt="" />
      </div>

      <div className="global-box-shadow uc-support__img uc-support__img--5">
        <img src={img09} alt="" />
        <PlayIcon2 />
      </div>
      <p className="uc-support__img-tagline uc-support__img-tagline--3">
        Virgil Abloh Basketball Film.
      </p>
    </div>
  )
}

function Impact({ images }) {
  const [
    img10,
    img11,
    img12,
    img13,
  ] = images;
  return (
    <div className="uc-impact">
      <Parallax className="uc-impact__heading" y={[80, -20]}>
        <h3 className="impact">
          <span>Impact</span>
          Impact
        </h3>
      </Parallax>

      <Parallax className="uc-impact__copy" y={[40, -80]}>
        <p className="uc-impact__copy-text">
          A series of films that go deeper than the surface of a brand message and dive into the fascinating themes of basketball culture, personal inspiration and the ethos of what unites Jordan’s global community.
        </p>
      </Parallax>

      <div className="global-box-shadow uc-impact__img uc-impact__img--1">
        <img src={img10} alt="" />
      </div>

      <div className="global-box-shadow uc-impact__img uc-impact__img--2">
        <img src={img11} alt="" />
      </div>
      {/* [418] neymar - scrub from site */}
      <div className="global-box-shadow uc-impact__img uc-impact__img--3 hide">
        <img src={img12} alt="" />
      </div>

      <div className="global-box-shadow uc-impact__img uc-impact__img--4">
        <img src={img13} alt="" />
      </div>
    </div>
  );
}

export default React.memo(UniteCapsulesPage);